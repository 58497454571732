import { API_URL } from '@/service/const';
import axios from 'axios';
import { update } from 'lodash-es';

export const SpaceService = {

    async getSpace(spaceId) {  
        let endpoint = `${API_URL}space/${spaceId}/`;
        console.log(endpoint)
        return axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
            try {
                if (response.status == 200) {
                    return Promise.resolve(response.data);
                }
            }
            catch (error) {
                return Promise.resolve(error);
            }
        }
        );
    },

    async getSpaces(withDefaultSpace = true) {
        let endpoint = `${API_URL}space/`;
        return axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
            try {
                if (response.status == 200) {
                    console.log(response)
                    let ret = response.data.results
                    if (!withDefaultSpace) {
                        return Promise.resolve(ret);
                    }
                    //append first element to the end of the array
                    let firstSpace = {
                        name: 'Unallocated Space',
                        description: 'All unassigned documents are stored here.',
                        isPrivate: true,
                        id: 0,

                    }
                    // append first space to the begining of the array
                    ret.unshift(firstSpace);
                    console.log(ret)
                    return Promise.resolve(ret);
                }
            }
            catch (error) {
                return Promise.resolve(error);
            }

    }
    )},
    async createSpace(newSpace) {
        let endpoint = `${API_URL}space/`;
        newSpace.isPrivate = true;
        await axios.post(endpoint, newSpace, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            try {
                if (response.status == 201) {
                    let ret = {
                        name: response.data.name,
                        description: response.data.description,
                        isPrivate: true
                    }

                    return Promise.resolve(ret);
                }
            }
            catch (error) {
                console.log(error.response.data);
                return Promise.resolve(error);
            }
        }   
        );


       
        // return Promise.resolve(newSpace);
    },

    async deleteSpace(spaceId) {
        let endpoint = `${API_URL}space/${spaceId}/`;
        await axios.delete(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            try {
                if (response.status > 199 && response.status < 300) {
                    return Promise.resolve(response);
                }
            }
            catch (error) {
                return Promise.resolve(error);
            }
        }
        );
    },
    async updateSpace(spaceId, updatedSpace) {
        let endpoint = `${API_URL}space/${spaceId}/`;
        await axios.put(endpoint, updatedSpace, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            try {
                if (response.status > 199 && response.status < 300) {
                    return Promise.resolve(response);
                }
            }
            catch (error) {
                return Promise.resolve(error);
            }
        }
        );

    }
   

};