// set base url
import axios from 'axios';
import { API_URL } from '@/service/const';
// import { get } from 'core-js/core/dict';
export const SubscriptionService = {

    async getSubscriptions() {
        let url = API_URL + 'user/current/subscriptions';
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            if (response.data) {
                return Promise.resolve(response.data);
            }
        }
        ).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
    },

    async getTransactions() {
        let url = API_URL + 'user/current/transactions';
         return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            if (response.data) {
                return Promise.resolve(response.data);
            }
        }
        ).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });

    },
}