<template>

<div v-if="profile.profile_name" dir="ltr" class=" w-full  align-items-start justify-content-start">

<Chip   class="py-0 pl-0 pr-3 mt-3 ">
    <span   class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">{{ Array.from(profile.profile_name)[0] }}</span>
    <span   class="ml-2 font-medium">{{ profile.profile_name }}, {{ profile.company_name }}</span>
</Chip>
<p  class="no-print ">{{ last_saved }}</p>
 </div>
</template>

<script setup>
const profile = defineProps(['profile_name', 'company_name']);

</script>