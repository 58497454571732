<template>

    <div class="p-4">
        <h3>Transactions</h3>

        <Card>
    <h2>Transactions</h2>

        <template #header>
        </template>
        <template #content>
            <div>
            <div class="flex justify-between items-center">
            </div>

            <DataTable :value="transactions" >
                <!-- <Column field="id" header="ID"></Column> -->
                <Column field="created" header="Date"></Column>
                <Column field="amount" header="Amount"></Column>
                <Column field="currency" header="Currency"></Column>
                   
            
                    <Column field="payment_method_details.card" header="status">
                    <template #body="slotProps">
            <Tag :value="slotProps.data.payment_method_details.card.network" severity="info" />

            <Tag :value="slotProps.data.payment_method_details.card.last4" :severity="getSeverity(slotProps.data.status)" />
        </template>
                </Column>
            </DataTable>
                    
            </div>
        </template>
        </Card>
    </div>
</template>

<script setup>

import { ref } from 'vue';
import { onMounted } from 'vue';
import { SubscriptionService } from '@/service/SubscriptionService';

const transactions = ref([]);

const getSeverity = (status) => {
    if (status === 'success') {
        return 'success';
    } else if (status === 'failed') {
        return 'danger';
    } else {
        return 'warning';
    }
}

onMounted(() => {
    // Fetch user transactions from the server
    SubscriptionService.getTransactions().then((data) => {
        console.log("transactions.value2",data)
        transactions.value = data;
    }).catch((error) => {
        console.error(error);
    });
});
</script>