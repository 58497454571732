

<template>
    <MenuHome />

    <div class="card">
      <div class="flex flex-row">
      <Profile class="w-full"/> 

        <Subscriptions class="w-full"/>
        </div>
    <Transactions class="w-full"/>

   
    </div>

  </template>
  