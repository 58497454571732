
<template>
  <Toast/>
    <div class="card no-print" v-if="subscription.free_trial">
        <MeterGroup :value="trial_bar_data" :min="0" :max="max_trials"/>

      <Button label="Upgrade" @click="comingSoon" text class="w-full no-print" />

    </div>

</template>

  <script setup>
import {useToast} from "primevue/usetoast";
const toast = useToast();

// coming soon method
const comingSoon = () => {
  toast.add({severity:'info', summary: 'Info', detail: 'Coming soon. Stay tuned!'});

}
</script>
<script>
import { PaymentService } from "@/service/PaymentService";

import { GuideService } from '@/service/GuideService';
import { ConsumptionService } from "@/service/ConsumptionService";

export default {

  data() {
    return {
      // Your component data here
      subscription: {
    "state": "",
    "period_start": "",
    "guides_count": 0,
    "free_trial": true,
    "max_trials": 0
},
      trial_bar_data: [{ label: '0/5 Guides Created', value: 0 }],
      max_trials: 5
    };
  },
  mounted() {
   
    ConsumptionService.getConsumption().then((response) => {
      this.subscription = response.data;
      const color = response.data.guides_count/response.data.max_trials > .6 ? '#ef4444' : '#3b82f6';
        this.trial_bar_data = [{ label: `You have used ${response.data.guides_count} out of ${response.data.max_trials} free account trials.`, value: response.data.guides_count, color: color}]
        this.max_trials = response.data.max_trials;
    });
  },
  methods: {
    checkout ()  {
    // PaymentService.checkout().then((response) => {
    //         window.location.href = response.url;
    // });
    // use toast to show message
}
  }
}

</script>
