import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Landing/HomeView.vue'
import ProfileView from '../views/user/ProfileView.vue'
import SpaceView from '../views/SpaceView.vue'
import SpacesView from '../views/SpacesView.vue'
import GuideView from '../views/GuideView.vue'
import PublicGuideView from '@/views/PublicGuideView.vue'
import LoginView from '../views/LoginView.vue'
import Signout from '../components/Signout.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import ExtensionBridge from '../components/ExtensionBridge.vue'
import SuccessfulPaymentView from '@/views/SuccessfulPaymentView.vue'
import CanceledPaymentView from '@/views/CanceledPaymentView.vue'
import ActivateAccount from '@/components/Misc/ActivateAccount.vue'
import ResetPassword from '@/components/Misc/ResetPassword.vue'
// import KhatwaGrid from '@/components/KhatwaGrid.vue'

const routes = [
  {
    path: '/spaces',
    name: 'spaces',
    component: SpacesView
  },
  {
    path: '/',
    name: 'home',
    component: SpacesView
  },
  
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/space/:id',
    name: 'space',
    component: SpaceView
  },
  {
    path: '/space/guide/:id',
    name: 'guide',
    component: GuideView
  },
  {
    path: '/public/space/guide/:id',
    name: 'public-guide',
    component: PublicGuideView
  },
  {
    path: '/signin',
    name: 'signin',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegistrationView
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/sync',
    name: 'sunc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ExtensionBridge
  },
  {
    path: '/payment/success/:sessionId',
    name: 'successful-payment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SuccessfulPaymentView
  },
  {
    path: '/activate/:uuid/:token',
    name: 'activate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ActivateAccount
  },
  {
    path: '/reset-password-confirm/:uuid/:token',
    name: 'reset-password-confirm',
    component: ResetPassword
  },
  {
    path: '/payment/canceled',
    name: 'successful-canceled',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CanceledPaymentView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
