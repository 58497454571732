<template>

    <div class="p-4">
            <h2>Subscriptions</h2>

        <Card>

        <template #header>
        </template>
        <template #content>
            <div>
            <div class="flex justify-between items-center">
                <h3>Active Subscriptions</h3>
            </div>

            <DataTable  :value="subscriptions" >
                <!-- <Column field="id" header="ID"></Column> -->
                <Column field="start_date" header="Created on"></Column>
                <Column field="current_period_start" header="Latest transaction"></Column>
                <Column field="current_period_end" header="Next transaction"></Column>
                <Column field="status" header="Status">  
                    <template #body="slotProps">
            <Tag :value="slotProps.data.status" :severity="getSeverity(slotProps.data.status)" />
        </template>
                </Column>
            </DataTable>
                    
            </div>
        </template>
        </Card>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { onMounted } from 'vue';
import { SubscriptionService } from '@/service/SubscriptionService';


const subscriptions = ref([]);

const getSeverity = (status) => {
    if (status === 'active') {
        return 'success';
    } else if (status === 'canceled') {
        return 'danger';
    } else {
        return 'warning';
    }
}


onMounted(() => {
    // Fetch user subscriptions from the server
    SubscriptionService.getSubscriptions().then((data) => {
        console.log("subs",data)
        subscriptions.value = data;
    }).catch((error) => {
        console.error(error);
    });
});
</script>