<template>
    <!-- forgot password -->
    <div class="card flex justify-center">
        <!-- <Button label="Show" @click="visible = true" /> -->
      
        <Dialog :visible="show_dialog" modal header="Edit Profile" :style="{ width: '25rem' }" :closable="false">
            <span class="text-surface-500 dark:text-surface-400 block mb-8">Generate a password reset email.</span>
            
            <div class="flex align-items-center justify-content-between mb-6">
                <label for="password-change-email" class="font-semibold w-24">Email</label>
                <InputText variant="filled"  class="w-full ml-3" autofocus id="password-change-email" ref="password_change_input" placeholder="Your email .... " autocomplete="off"  v-model="password_change_email" />
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Change password" @click="change_password"></Button>
                <Button type="button" label="Cancel" severity="secondary" @click="show_dialog = false"></Button>
            </div>
        </Dialog>
        
    </div>
    <!-- end forgot password -->
     <div class="card">
        <Message :visible="true" :severity="message_severity">{{ message_text }}</Message>
    </div>
<div class="flex flex-wrap align-items-center justify-content-center" style="height: 100%; min-height: 100vh">

<div class="surface-card p-4 shadow-2 border-round w-full lg:w-5">
    <div class="text-center mb-5">
        <img src="/logo-no-background.png" alt="Image" height="120" class="mb-3" />
        <div class="text-900 text-3xl font-medium mb-3"></div>
        <!-- <div class="text-600 text-3xl font-medium mb-3">Welcome Back</div> -->
        <span class="text-600 font-medium line-height-3">Don't have an account? </span>
        <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" href="/register">Become an early adopter!</a>
    </div>

    <div>
        <label for="username" class="block text-900 font-medium mb-2">Username or Email</label>
        <InputText variant="filled" autofocus id="username" type="text" v-model="email" class="w-full mb-3" />

        <label for="password1" class="block text-900 font-medium mb-2">Password</label>
        <InputText variant="filled"  id="password1" type="password" v-model="password" v-on:keyup.enter="onEnter(email,password)" class="w-full mb-3" />

        <div class="flex align-items-center justify-content-between mb-6">
            <div class="flex align-items-center">
                <Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
                <label for="rememberme1">Remember me</label>
            </div>
            <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" @click="forgotPassword(this)">Forgot password?</a>
        </div>

        <Button label="Sign In" icon="pi pi-user" @click="loginApi(email,password)" class="w-full"></Button>
    </div>
</div>
</div>

</template>

<script setup>
import { ref } from 'vue';
// import onmount from 'vue';
import {onMounted} from 'vue';

const checked = ref(true);
const email = ref('');
const password = ref('');
const message_text = ref('');
const message_severity = ref('');
const message_visible = ref(false);
const show_dialog = ref(false);
const password_change_email = ref('');
const password_change_input = ref(null);
const  loginApi = (email,password) => {
            AuthService.login(email, password).then((data) => {
                // this.$router.push('/dashboard');
            });
        }
const onEnter = (email, password) => {
    loginApi(email,password);
}
// on mount
onMounted(() => {
        // check get vars for activation key
        let url = new URL(window.location.href);
        let activation_key = url.searchParams.get("activation");
        console.log("activation_key",activation_key);
        if (activation_key) {
            message_visible.value = true;
            if (activation_key=="activation-success"){ 
                message_text.value = "Account activated successfully!";
                message_severity.value = "success";
                // AuthService.activateAccount(activation_key);
            }else if (activation_key=="activation-failed"){
                message_text.value = "Account activation failed!";
                message_severity.value = "error";
            }
        }
        let reset_key = url.searchParams.get("reset");
        if (reset_key) {
            message_visible.value = true;
            console.log("message_visible",message_visible.value);
             if(reset_key=="password-change-success"){
                message_text.value = "Email sent! Please check your email!";
                message_severity.value = "success";

            }else if(reset_key == "password-change-failed"){
                message_text.value = "Password change failed! Are you sure this is the right email?";
                message_severity.value = "error";
            }else if(reset_key == "password-change-complete"){
                
                message_text.value = "Password changed successfully!";
                message_severity.value = "success";
            }else if(reset_key == "password-change-invalid"){
                message_text.value = "Invalid password reset link!";
                message_severity.value = "error";
            }

            // AuthService.activateAccount(activation_key);
        }
        let login_key = url.searchParams.get("login");
        if (login_key) {
            message_visible.value = true;
            console.log("message_visible",message_visible.value);
             if(login_key=="failed"){
                message_text.value = "Login failed! Are you sure this is the right email and password?";
                message_severity.value = "error";

            }
        }

    });

    // forgot password method
    const forgotPassword = (that) => {
        show_dialog.value = true;
        console.log("password_change_input",password_change_input)
        console.log("refs",that.$refs.password_change_input);
        // password_change_input.value.focus();
        // focus on element with id password-change-email
        // document.getElementById('password-change-email').focus();
        // get element by ref password-change-email


    }
    const change_password = () => {
        AuthService.changePasswordRequest(password_change_email.value).then((data) => {
            console.log("change password", data);
        });
    }
</script>

<script>
import { AuthService } from '@/service/AuthService';

export default {
    data() {
        return {
            email: '',
            password: '',
            checked: true
        }
    },
    methods: {
  
        
       
     
    }
  }
  </script>