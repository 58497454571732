
<template>
    <h2>
        {{ "Spaces" }}
    </h2>
    <Toast />
    <Button icon="pi pi-plus" label="New Space" class="float" @click="toggleDialog" />
    <div class="card flex justify-content-center">
        <Dialog :visible="spaceGridVisible" modal header="Create Space" closeOnEscape  :style="{ width: '25rem' }" :closable="false">
            <span class="p-text-secondary block mb-5">Enter Space details.</span>
            <div class="flex align-items-center gap-3 mb-3">
                <label for="name" class="font-semibold w-6rem">Name*</label>
                <InputText id="name" autofocus v-model="newSpace.name" class="flex-auto" autocomplete="off" />
            </div>
            <div class="flex align-items-center gap-3 mb-5">
                <label for="description" class="font-semibold w-6rem">Description*</label>
                <InputText id="description"  v-model="newSpace.description"  class="flex-auto" autocomplete="off" />
            </div>
            <div class="flex justify-content-end gap-2">
                <Button type="button" label="Cancel" severity="secondary"  @click="spaceGridVisible = false"></Button>
                <Button type="button" label="Create" @click="createSpace"></Button>
            </div>
        </Dialog>
    </div>
    <div class="card">
        <DataView :value="spaces" :layout="layout" >
        
            <template #grid="slotProps">
                <div class="grid grid-nogutter">
                    <TransitionGroup name="fade"  class="animation-contianer">

                    <div v-for="(item, index) in slotProps.items" :key="item.url" class="col-12 sm:col-6 md:col-4 xl:col-3 p-2">
                        <div class="p-4 border-1 surface-border surface-card border-round flex flex-column">
                            <!-- <div class="surface-50 flex justify-content-center border-round p-3">
                                <div class="relative mx-auto">
                                    <img class="border-round w-full" :src="`https://primefaces.org/cdn/primevue/images/product/${item.image}`" :alt="item.name" style="max-width: 300px"/>
                                    <Tag :value="item.inventoryStatus" :severity="getSeverity(item)" class="absolute" style="left: 4px; top: 4px"></Tag>
                                </div>
                            </div> -->

                            <div class="pt-4">
                                <div class="flex flex-row justify-content-start align-items-start gap-2">

                                    <div>
                                        <div class="text-lg font-medium text-900 mt-1">{{ item.name }} <Badge v-if="item.guides"  :value="item.guides.length"></Badge></div>
                            

                                        <span  v-if="item.id > 0"  class="font-medium text-secondary text-sm rows-2">{{item.description }} </span>
                                        <span v-if="!item.guides" class="font-medium text-secondary text-sm">Find unallocated guides here</span>
                                    
                                   
                                    </div>
                                 
                                </div>
                                <div class="flex flex-column gap-4 mt-4">
                                    <!-- <span class="text-2xl font-semibold text-900">${{ item.price }}</span> -->
                                    <div class="flex flex-row justify-content-between flex-wrap">
                                        <router-link :to="`/space/${ item.id }`"  rel="noopener">
                                            <Button icon="pi pi-folder-open" label="Open" :disabled="item.inventoryStatus === 'OUTOFSTOCK'" class="flex-auto white-space-nowrap"></Button>
                                        </router-link>
                                        <div>
                                            <!-- <Button icon="pi pi-share-alt"  v-if="item.id > 0" outlined></Button> -->
                                            <Button icon="pi pi-trash" severity="danger" v-if="item.id > 0" text @click="deleteSpace(item.id)" ></Button>
                                    
                                        </div>
                                      </div>
                                    <!-- <div class="flex justify-content-between gap-2">

                                        <Button icon="pi pi-share-alt" outlined></Button>
                                        <Button icon="pi pi-trash" outlined></Button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    </TransitionGroup>
                </div>
            </template>
        </DataView>
    </div>
  </template>
<script setup>
  import {useToast} from 'primevue/usetoast';
  import { ref } from 'vue';
  import { onMounted } from 'vue';

  import { SpaceService } from '@/service/SpaceService';
  const toast = useToast();


     const spaces = ref(null);
     const    layout=  ref('grid');
     const   spaceGridVisible= ref(false);
     const   newSpace= ref({
                name: '',
                description: ''
            })
   
    onMounted(() => {
        SpaceService.getSpaces().then((data) => {
            console.log(data);
            spaces.value = data.slice(0, 20)});
    });


        const toggleDialog = ()=> {
            spaceGridVisible.value = !spaceGridVisible.value;
            console.log("now is: " + spaceGridVisible.value)
        }
        const createSpace = async ()=> {
            if (newSpace.value.name ===''){
                // this.$toast.add({severity:'error', summary: 'Error', detail: 'Name is required', life: 3000});
                toast.add({severity:'error', summary: 'Error', detail: 'Name is required', life: 3000});
                return;
            }
            if (newSpace.value.description ===''){
                // this.$toast.add({severity:'error', summary: 'Error', detail: 'Description is required', life: 3000});
                toast.add({severity:'error', summary: 'Error', detail: 'Description is required', life: 3000});
                return;
            }
            console.log(newSpace.value.name);
            await SpaceService.createSpace(newSpace.value).then((data) => {
                SpaceService.getSpaces().then((data) => (spaces.value = data.slice(0, 20)));

            });
            spaceGridVisible.value = false;
        }
         const deleteSpace = async (id) =>{
            await SpaceService.deleteSpace(id).then((data) => {
                SpaceService.getSpaces().then((data) => (spaces.value = data.slice(0, 20)));

            });
        }
     
    
  
  </script>
  