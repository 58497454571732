import { createApp } from 'vue'
import "primeflex/primeflex.css";
import "./assets/animations.css";
import "./assets/printing.css";
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/aura-light-green/theme.css";
import 'primevue/resources/primevue.min.css'
import Card from 'primevue/card';
import Menubar from 'primevue/menubar';
import HelloWorld from './components/HelloWorld.vue'
import HistoryCard from './components/HistoryCard.vue'
import ForceAuthentication from './components/ForceAuthentication.vue';
import HistoryGrid from './components/HistoryGrid.vue'
import DataView from 'primevue/dataview';
import AutoComplete from 'primevue/autocomplete';
import Tag from 'primevue/tag';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Button from 'primevue/button';
import MenuHome from './components/MenuHome.vue'
import SpacesGrid from './components/SpacesGrid.vue';
import KhatwaGrid from './components/KhatwaGrid.vue';
import PublicKhatwaGrid from './components/PublicKhatwaGrid.vue';
import Login from './components/Login.vue';
import Registration from './components/Registration.vue';
import Signout from './components/Signout.vue';
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import Badge from 'primevue/badge';
import ToggleButton from 'primevue/togglebutton';
import Spaces from './components/Spaces.vue';
import Subscriptions from './components/User/Subscriptions.vue';
import Transactions from './components/User/Transactions.vue';
import ForgotPassword from './components/User/ForgotPassword.vue';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import KhatwaCard from './components/KhatwaCard.vue';
import titleMixin from './mixins/titleMixin'
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import SpeedDial from 'primevue/speeddial';
import Breadcrumb from 'primevue/breadcrumb';
import Tooltip from 'primevue/tooltip';
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import InputMask from 'primevue/inputmask';
import FloatLabel from 'primevue/floatlabel';
import ExtensionBridge from './components/ExtensionBridge.vue';
import Profile from './components/User/Profile.vue';
import Consumption from './components/Consumption.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';

import MeterGroup from 'primevue/metergroup';
import Chip from 'primevue/chip';

import MegaMenu from 'primevue/megamenu';
import TranslationBox from './components/TranslationBox.vue'; 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import GuideAuthor from './components/GuideAuthor.vue';
import Home from './components/Landing/Home.vue';
import PublicMenu from './components/Landing/PublicMenu.vue';
import ActivateAccount from './components/Misc/ActivateAccount.vue';
import ResetPassword from './components/Misc/ResetPassword.vue';
import Vue3Lottie from 'vue3-lottie'
// localizations
const app = createApp(App);
app.use(store).use(router);
app.use(ToastService);
app.use(ConfirmationService);
app.use(Vue3Lottie);
app.mixin(titleMixin)
app.directive('tooltip', Tooltip);
app.component('GuideAuthor', GuideAuthor);
app.component('Card', Card);
app.component('Menubar', Menubar);
app.component('HelloWorld', HelloWorld);
app.component('HistoryCard', HistoryCard); 
app.component('DataView', DataView); 
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('HistoryGrid', HistoryGrid);
app.component('Tag', Tag);
app.component('Button', Button);
app.component('MenuHome', MenuHome);
app.component('SpacesGrid', SpacesGrid);
app.component('KhatwaGrid', KhatwaGrid);
app.component('PublicKhatwaGrid', PublicKhatwaGrid);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('Textarea', Textarea);
app.component('Badge', Badge);
app.component('ToggleButton', ToggleButton);
app.component('Spaces', Spaces);
app.component('Login', Login);
app.component('Signout', Signout);
app.component('Checkbox', Checkbox);
app.component('Dialog', Dialog);
app.component('AutoComplete',AutoComplete);
app.component('Toast', Toast);
app.component('Breadcrumb', Breadcrumb);
app.component('SpeedDial', SpeedDial);
app.component('FileUpload', FileUpload);
app.component('ProgressBar', ProgressBar);
app.component('Registration',Registration);
app.component('FloatLabel', FloatLabel);
app.component('ExtensionBridge', ExtensionBridge);
app.component('Profile', Profile);  
app.component('Image', Image);
app.component('MeterGroup', MeterGroup);
app.component('Consumption', Consumption);
app.component('Chip', Chip);
app.component('MegaMenu', MegaMenu);
app.component('TranslationBox', TranslationBox);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Home', Home);
app.component('PublicMenu', PublicMenu);
app.component('Subscriptions', Subscriptions);
app.component('Transactions', Transactions);
app.component('ActivateAccount', ActivateAccount);
app.component('Message', Message);
app.component('ForgotPassword', ForgotPassword);
app.component('ResetPassword', ResetPassword);
app.component('ConfirmDialog', ConfirmDialog);
// app.component('KhatwaCard', KhatwaCard);
app.component('ForceAuthentication', ForceAuthentication);
app.use(PrimeVue, { /* options */ });
app.mount('#app');

