
<template>
    <h2>
        {{ space.name || "Default space" }}
    </h2>
    <div class="card">
        <DataView :value="guides" :layout="layout">
            <template #empty>
            <div class="p-4 text-center">
                <h3>Do you have the browser extension installed? <a href="https://chromewebstore.google.com/detail/guide-clicks-automate-use/cicmijfcdajjoecdmhgfblnjjfodomap">Google extensions store</a></h3>
                <span class="p-text-secondary">No Documents found.</span>
                <br/>
                <span class="p-text-secondary">Create your first document or move a document from another space.</span>
            </div>
         </template>
            <template #grid="slotProps">
                <div class="grid grid-nogutter">
                    <TransitionGroup name="fade"  class="animation-contianer">

                    <div v-for="(item, index) in slotProps.items" :key="item.id" class="col-12 sm:col-6 md:col-4 xl:col-4 p-2">
                        <div class="p-4 border-1 surface-border surface-card border-round flex flex-column">
                            <div class="surface-50 flex justify-content-center border-round p-3">
                                <div class="relative mx-auto">
                                    <!-- {{ console.log(item.images[0]) }} -->
                                    <img class="border-round w-full" v-if="item.images[0]" :src="`${item.images[0].imageBase64}`" :alt="item.name" style=" max-height: 150px; object-fit: contain;"/>
                                    <!-- <div class="border-round w-full bg-gray-200" style="height: 200px"></div> -->
                                    <!-- <Tag :value="item.inventoryStatus" :severity="getSeverity(item)" class="absolute" style="left: 4px; top: 4px"></Tag> -->
                                </div>
                            </div>
                            <div class="pt-4">
                                <div class="flex flex-row justify-content-between align-items-start gap-2">
                                    <div>
                                        <!-- <span class="font-medium text-secondary text-sm">{{ item.metadata.title || "Title" }}</span> -->
                                        <div class="text-lg font-medium text-900 mt-1 mb-3">{{ item.metadata.title || "Title" }}</div>
                                        <div class="tags">
                                            <Tag  v-if="item.images[0]"  :value="item.images[0].title" :severity="info" style="left: 4px; top: 4px"></Tag>

                                        </div>
                                   
                                    </div>
                                 
                                </div>
                                <div class="flex flex-column gap-4 mt-4">
                                    <!-- <span class="text-2xl font-semibold text-900">${{ item.price }}</span> -->
                                    <div class="flex gap-2">
                                        <div class="flex-auto white-space-nowrap">
                                            <router-link :to="`guide/${item.id}`" rel="noopener">
                                                <Button icon="pi pi-file-edit"  label="Open"  ></Button>
                                            </router-link>
                                        </div>
                                        <!-- <Button icon="pi pi-share-alt" outlined></Button> -->
                                        <Button icon="pi pi-trash" severity="danger" @click="deleteGuide(index)" text></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </TransitionGroup>
                </div>
            </template>
        </DataView>
    </div>
  </template>
  
  <script>
//   import { guideservice } from '@/service/guideservice';
  import { GuideService } from '@/service/GuideService';
import { SpaceService } from '@/service/SpaceService';
  export default {
    data() {
        return {
            guides: null,
            space: {id: 0, name: ''},
            layout: 'grid'
        }
    },
    mounted() {
        // guideservice.getguides().then((data) => (this.guides = data.slice(0, 12)));
        if (this.$route.params.id>0){
        SpaceService.getSpace(this.$route.params.id).then((data) => {this.space = data;});
        }
        GuideService.getSpaceGuides(this.$route.params.id).then((data) => {console.log(data);this.guides = data;});
    },
    methods: {
     deleteGuide(index) {
        let id = this.guides[index].id;
        GuideService.deleteGuide(id).then((response) => {
          console.log("deleteGuide",response);
        });
        console.log("deleteGuide");
        // removing from array
        this.guides.splice(index, 1);
      }
    }
  }
  </script>
  