
<template>

</template>


<script setup>
// importing on mount
import { onMounted } from 'vue';
// importing auth service
import { AuthService } from '@/service/AuthService';
// importing constants from const.js service
import { ROOT } from '@/service/const';


// on mount
onMounted(() => {
    // calling register function
    // get current page url
    let page_url = window.location.pathname;
    // remove the domain
    // console.log("key",page_url)
    if (page_url.startsWith('/')){
        page_url = page_url.substring(1);
    }
    AuthService.activateAccount(page_url);
});


</script>