
<template>
    <div class="card">
        
    </div>  
</template>

<script setup>
import { ref, onMounted } from 'vue';
// import {LanguageService} from '@/services/LanguageService';
import { LanguageService } from '@/service/LanguageService';



const products = ref([]);
onMounted(() => {
  
LanguageService.getLanguages().then((response) => {
console.log(response);
      products.value = response;
   });});

</script>

