<meta name="viewport" content="initial-scale=1" />
<template >
 
  <div class="p-4">
   
    <div >
      <h2> Documents Author</h2>
          <Card class="flex align-items-center justify-content-center">
         
        <template #title>
          <ToggleButton v-model="editing" offLabel="Edit" onLabel="Cancel" offIcon="pi pi-pencil" onIcon="pi pi-times"  />

          <!-- <div class="flex-row">

          <Tag v-if="!profile.free_trial" icon="pi pi-info-circle" severity="info" value="Free Trial"></Tag>
            <Tag v-else icon="pi pi-user" value="GP Plus"></Tag>
            <Button  v-if="!profile.free_trial" class="w-full" label="Upgrade" />
            </div>   -->
          <!-- <div class="p-d-flex p-ai-center">
            <Image src="/profile-placeholder.png" alt="Image" width="250" preview  class="flex align-items-center justify-content-center"/>       
          </div> -->
            

        
          
        </template>
        <template #content>
          <div>

              <InputText v-model="profile.name" autofocus placeholder="Name" class="w-full mb-3" :disabled="!editing" />
              <InputText v-model="profile.company_name" placeholder="Compnany name" class="w-full mb-3" :disabled="!editing" />
            </div>
          <InputText v-model="profile.company_url" placeholder="https://www.yourcompany.com" class="w-full mb-3" :disabled="!editing"/>
          <Button v-if="editing" label="Submit" @click="submit" />
          
        </template>
      </Card>
    </div>
    

  </div>
  </template>
  
  <script>
import { ProfileService } from '@/service/ProfileService';

  // import { Card } from 'primevue/card';
  export default {
    
    data() {
      return {
        editing: false,
        profile: {
          image: 'path/to/image.jpg', // Replace with your actual image path
          name: 'Your J', // Replace with your actual name
          company_name: 'Your Company Name', // Replace with your actual company name
          company_url: 'https://your-company-url.com', // Replace with your actual company URL
          free_trial: 'Free', // Replace with your actual free_trial status
        }
      };
    },
    mounted() {
      // Fetch user profile data from the server
      ProfileService.getProfile().then((data) => {
        this.profile = data;
      }).catch((error) => {
        console.error(error);
      }); 
    },
    methods: {
      submit() {
        // Save the updated profile data to the server
        ProfileService.saveProfile(this.profile).then(() => {
          this.editing = false;
        }).catch((error) => {
          console.error(error);
        });
      }
    }

  };
  </script>