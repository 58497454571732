// set base url
import axios from 'axios';
import { API_URL } from '@/service/const';
export const ConsumptionService = {

    async getConsumption() {
        let url = API_URL + 'subscription/';
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            if (response.data) {
                return Promise.resolve(response);
            }
        }
        ).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
    }

}