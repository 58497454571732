
        <template>
    <ForceAuthentication />

    <div class="card  no-print">
        <Menubar :model="items">
            <template #item="{ item, props, hasSubmenu }">
                <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                    <a :href="href" v-bind="props.action" @click="navigate">
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                    </a>
                </router-link>
                <a v-else  :href="item.url" :target="item.target" v-bind="props.action">
                    <span :class="item.icon" />
                    <span class="ml-2">{{ item.label }}</span>
                    <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
                </a>
            </template>
           
        </Menubar>
    </div>
    <Consumption />
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';

const router = useRouter();
const loggedOutMenu = [
           
            {
                label: 'Sign in',
                icon: 'pi pi-sign-in',
                route: '/signin'
            },
            
        ]
const loggedInMenu = [
{
                label: localStorage.getItem('username') || 'My Account',
                icon: 'pi pi-user',
                route: '/profile'
            },
            {
                label: 'Sign Out',
                icon: 'pi pi-sign-out',
                route: '/signout'
            },
        ]
let authMenu = [];
if (localStorage.getItem('user')) {
    authMenu= loggedInMenu;
} else {
    authMenu = loggedOutMenu;
}
const items = ref([
    {
        label: 'Spaces',
        icon: 'pi pi-folder-open',
        route: '/spaces'
    },
    // {
    //     label: 'Teams',
    //     icon: 'pi pi-users',
    //     items: [
    //         {
    //             label: 'Members',
    //             icon: 'pi pi-user',
    //             route: '/'
    //         },
    //         {
    //             label: 'Invite',
    //             icon: 'pi pi-user-plus',
    //             route: '/'
    //         },
            
    //     ]
    // },
    // {
    //     label: 'Support',
    //     icon: 'pi pi-info',
    //     route: '/'
    // },
    {
        label: 'Account',
        icon: 'pi pi-user',
        items: authMenu
    }
]);
</script>
