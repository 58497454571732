
<template>
  <error-boundary>
    <div id ="printable">
    <div class="card">
        <Toast />

        <DataView :value="guide"   :dir="metadata.direction" :layout="layout" class="flex flex-wrap align-items-center justify-content-center">
           
            <template #grid="slotProps">
                <GuideAuthor :profile_name="profile.name" :company_name="profile.company_name"/>
                <!-- <Chip class="py-0 pl-0 pr-3 mt-3">
                    <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">{{ Array.from(profile.name)[0] }}</span>
                    <span class="ml-2 font-medium">{{ profile.name }}, {{ profile.company_name }}</span>
                </Chip> -->
            <div>
                <Menubar data-html2canvas-ignore="true" dir="ltr" class="mt-5 no-print" :model="headerMenuItems">
                    <template #start>
                        <Chip v-if="data.is_public" class="bg-primary" label="Public" icon="pi pi-globe" />
                        <Chip v-else label="Private" icon="pi pi-lock" />
                    </template>
                    <template #item="{ item, props, hasSubmenu }">
                        <div v-if="!item.only_public || (item.only_public && data.is_public)">

                        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                            <a :href="href" v-bind="props.action" @click="navigate">
                                <span :class="item.icon" />
                                <span class="ml-2">{{ item.label }}</span>
                            </a>
                        </router-link>
                        <a v-else  :href="item.url" :target="item.target" v-bind="props.action">
                            <span :class="item.icon" />
                            <span class="ml-2">{{ item.label }}</span>
                            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
                        </a>
                    </div>

                    </template>
                    <template #end>
                       <b> <label  dropdown  optionLabel="name">{{ currentSpace.name }}</label></b>
                    </template>
                </Menubar>
                
            

                <h1><label type="text" placeholder="title" style="font-size: inherit" rows="1" class="w-full"  autoResize> {{metadata.title}}</label> </h1>
                <!-- <Textarea v-model="guide.description" placeholder="Summary" rows="5" class="w-full" /> -->
                <Card>
                    <template #title>
                       
                        </template> 
                        <template #content>
                                
                            <div class="flex flex-row align-items-center " >
                                <Button  disabled icon="pi pi-link" text  class="w-2rem h-2rem p-0"   />
                                
                                <a :href="data.go_to_url" dir="ltr"  target="_blank">{{ data.go_to_url }}</a> 
                            </div>

                        </template>
                </Card>
            </div>
                <div class="list list-nogutter">
                    
                    <TransitionGroup name="fade"  class="animation-contianer">
                        <div v-for="(item, index) in slotProps.items" :key="item"   style="max-width: 700px">
                    <section>
                        
                                <Card class="list-item" style=" margin:15px;">
                               
                               <template #title>
                                
                                   <div class="flex flex-row" >
                                       <Button disabled  rounded :label="`${index+1}`" class="w-2rem h-2rem p-0" style="margin:5px;" />
                                       <Textarea disabled type="text"  rows="1" class="w-full" autoResize style="color: var(--secondary-color);" v-model="item.actionText" />
                                   </div>
                               </template>
                               <template #content >
                                   <p class="m-2" v-if="item.description != '' && item.description  ">
                                       <Textarea disabled v-model="item.description" rows="1" style="color: var(--secondary-color);" class="w-full" autoResize />
                                   </p>
                                   <div v-if="item.imageBase64" class="mx-auto relative">
                                       <img class="border-round w-full" :src="`${item.imageBase64}`"   :alt="item.name"/>
                                       <img v-if ="item.action" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Red_circle.svg/400px-Red_circle.svg.png"  class="absolute" :style="`${getCircleStyle(item)}`" outlined></img>
                                   </div>
                                 
                               </template>

                           </Card>
                    </section>

                        </div>
                    </TransitionGroup>
                </div>
            </template>
        </DataView>
        <div class="flex align-content-center flex-wrap w-full m-3">
            <p class="w-full flex justify-content-center align-items-center">{{ profile.company_name }}</p>
            <a :href="profile.company_url" target="_blank" class="text-underline text-secondary text-sm  mb-3 w-full flex justify-content-center align-items-center">{{ profile.company_url }}</a>
            
        </div>
    </div>
</div>
</error-boundary>

  </template>

  <script>
  import { GuideService } from '@/service/GuideService';
  import { ref } from 'vue';
  export default {
    data() {
        return {
            guide: null,
            metadata: {'direction': 'ltr'},
            layout: 'grid',
            spaces: [],
            currentSpace: {"id":0, "name":""},
            updateURLVisible: false,
            cachedUrl: null,
            direction: 'ltr',
            directionIcon: 'pi pi-arrow-left',
            profile: {
                image: '', // Replace with your actual image path
                name: '', // Replace with your actual name
                company_name: '', // Replace with your actual company name
                company_url: '', // Replace with your actual company URL
            },
            data: {
                is_public: false,
                go_to_url: ''
            }


        }
    },
    mounted() {
    
        this.profile= {
            image: localStorage.getItem('image'),
            name: localStorage.getItem('name'),
            company_name: localStorage.getItem('company_name'),
            company_url: localStorage.getItem('company_url')

        }
   
        GuideService.getGuide(this.$route.params.id).then((data) => {
            this.guide = data.images;
            this.metadata = data.metadata;
            this.data = data;
            
             
            });
      
    },
    methods: {
        exportToPDF() {
            window.print();
    },
        getCircleStyle(item) {
            return "left: " + item.x + "%; top: " + item.y + "%; max-width: 50px; max-height: 50px; margin-top: -25px; margin-left: -25px;";
        },
        
       
     
    }
  }
  </script>
  <script setup>
  import { ref } from 'vue';
  import { useToast } from "primevue/usetoast";
    const toast = useToast();
    const headerMenuItems = ref([
    
  
    {
                        label: 'PDF',
                        icon: 'pi pi-download',
                        command: () => {
                            window.print();
                }
                    },
                    {
                        label: 'Copy link',
                        icon: 'pi pi-link',
                        only_public: true,
                        command: () => {
                            navigator.clipboard.writeText(window.location.href);
                            toast.add({ severity: 'info', summary: 'Link sharing', detail: 'Copied to clipboard', life: 3000 });
                        }
                    },
  
]);
  </script>