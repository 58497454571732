// // set base url
// import axios from 'axios';
// import { API_URL } from '@/service/const';


// const API_URL = 'https://127.0.0.1:8000/api/v1/';
export const LanguageService = {
    async getLanguages() {
        let languages = {
            languages:[{  id: 1, label: 'English',code:'en', direction: 'ltr' },
            { id: 2, label: 'Arabic',code:'ar', direction: 'rtl' },
            { id: 3, label: 'French',code:'fr',direction: 'ltr' },
            { id: 4, label: 'Spanish',code:'es', direction: 'ltr' }],
        terms: [
            {id: 1, shortname:'clickon', translations: [{language: 'en', value: 'Click on'}, {language: 'ar', value: 'انقر على'}, {language: 'fr', value: 'Cliquez sur'}, {language: 'es', value: 'Haga clic en'}]},
            {id: 2, shortname:'clickhere', translations: [{language: 'en', value: 'Click here'}, {language: 'ar', value: 'انقر هنا'}, {language: 'fr', value: 'Cliquez ici'}, {language: 'es', value: 'Haga clic aquí'}]},


        ]
    }

       
     
        return Promise.resolve(languages);
    },
    replaceTermsInString(data,from_language, language, string) {

        let terms = data.terms;
        console.log("received terms:",terms, "language:",language, "string:",string)
        let replacedString = string;
        terms.forEach((term) => {
            let translation = term.translations.find((translation) => translation.language === language);

            console.log("translation:",translation)

            let sourceTerm = term.translations.find((translation) => translation.language === from_language);

            if (translation) {
                replacedString = replacedString.replace(sourceTerm.value, translation.value);
            }

        });
        console.log("received terms:",terms, "language:",language, "translated:",replacedString)

        return replacedString;
    
    }

 
}