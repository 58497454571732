
<template>
    <div class="card flex justify-center">
        <!-- <Button label="Show" @click="visible = true" /> -->
      
        <Dialog :visible="visible" modal header="Edit Profile" :style="{ width: '25rem' }" :closable="false">
            <span class="text-surface-500 dark:text-surface-400 block mb-8">Generate a password reset email.</span>
            
            <div class="flex items-center gap-4 mb-8">
                <label for="email" class="font-semibold w-24">Email</label>
                <InputText id="email" class="flex-auto" autocomplete="off" placeholder ="Your email ...." autofocus />
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
                <Button type="button" label="Save" @click="visible = false"></Button>
            </div>
        </Dialog>
        
    </div>
</template>

<script setup>
import { ref,toRef } from "vue";
import { onMounted } from "vue";
defineEmits(['vis'])
const props = defineProps({
  visible: Boolean,
   default: () => false
});
const visible = ref(false);
// making visible reactive

let email_element = ref(null);

// onmounted
// onMounted(() => {
//   visible.value = Boolean(props.visible);

// });
const textFocus = (e) => {

    email_element.value = e.target;
};

</script>
