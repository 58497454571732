import { createI18n } from 'vue-i18n'
export const translationData = {
  locale: 'ar',
  fallbackLocale: 'en',
  messages: {
    en: {
      message: {
        "go_to": 'Go to',
        "Click on": 'Click on',
        "Click here": 'Click here',
      }
    },
    ar: {
      message: {
        "go_to": 'انتقل إلى',
        "Click on": 'انقر على',
        "Click here": 'انقر هنا',
      }
    }
  }
}
export const i18n = createI18n(translationData)