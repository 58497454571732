

<template>
   <head>
    <title>Guide Clicks</title>
</head>
    
    <main>
      <RouterView />
    </main>
  </template>
  <script>
  export default {
    title: 'Guided Clicks'
  }
  </script>