import { API_URL } from "./const";
import axios from 'axios';


export const GuideService = {

    async getSpaceGuides(space_id){
        let endpoint = API_URL + `space/${space_id}/guide`
        return axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log("guide space",response.data.results)
                return Promise.resolve(response.data.results);
            
            
            }

            );

    },
    async getGuide(guide_id){
        let endpoint = API_URL + `guide/${guide_id}/`
        console.log("getting guide",endpoint)
        let headers =  {
            'Content-Type': 'application/json'
           
        }
        if (localStorage.getItem('access')){
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('access')
        }
        return axios.get(endpoint, {
            headers: headers
            }).then(response => {
                return Promise.resolve(response.data);
            
            
            }

            );
    },
    async updateGuide2(guide_id, body){
        // remove imageBase64 from images array
       
        let data = JSON.stringify(body);
        console.log("updating guide",data)

        let endpoint = API_URL + `guide/${guide_id}/`
        return axios.put(endpoint,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }

            );
    },
    async createGuide(body){
        // remove imageBase64 from images array
        
        let data = JSON.stringify(body);
        console.log("creating guide")
        console.log(data)

        let endpoint = API_URL + `guide/`
        return axios.post(endpoint,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }

            )
            .catch(error => {
                // if 401 redirect to login
                console.log(error)
                if (error.response.status === 401){
                    window.location.href = '/register?sync=failed&message=You need to be logged in to create a guide';
                }
                
            })
            ;
    }
    ,
    async update(guide_id,guide){
        // remove imageBase64 from images array
        // let space_id = body.metadata.space;

        // images = body.images.map(({ imageBase64, ...rest }) => rest);
        // let body = {
        //     is_public: is_public,
        //     metadata: metadata,
        //     images: images,
        //     space: space_id,
        // }
        let body = {... guide}
        console.log("updating guide",body)
        // body.images = images;
        // remove imageBase64 from images array inside body object
        body.images = body.images.map(({ imageBase64, ...rest }) => rest);
        let data = JSON.stringify(body);
        console.log("updating guide DATA",data)

        let endpoint = API_URL + `guide/${guide_id}/`
        return axios.put(endpoint,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }

            );
    },
    async updateGuide(guide_id, images, metadata, is_public){
        // remove imageBase64 from images array
        let space_id = metadata.space;
        images = images.map(({ imageBase64, ...rest }) => rest);
        let body = {
            is_public: is_public,
            metadata: metadata,
            images: images,
            space: space_id,
        }
        let data = JSON.stringify(body);
        console.log("updating guide",data)

        let endpoint = API_URL + `guide/${guide_id}/`
        return axios.put(endpoint,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }

            );
    },
    async deleteGuide(guide_id){
        let endpoint = API_URL + `guide/${guide_id}/`
        return axios.delete(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }
                    
                    );
    }
    ,
    async deleteGuideStep(step_id){
        let endpoint = API_URL + `guide-step/${step_id}/`
        return axios.delete(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }
                
                );
    },
    async updateGuideStep(step_id, body){
        let data = JSON.stringify(body);
        console.log("updating guide step",data)

        let endpoint = API_URL + `guide-step/${step_id}/`
        return axios.put(endpoint,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }

            );
    }
    ,
    async updateGuideSpace(guide_id, space_id){
        let body = {
            space: space_id
        }
        let data = JSON.stringify(body);
        console.log("updating guide",data)
        let endpoint = API_URL + `guide/${guide_id}/`
        return axios.put(endpoint,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }}).then(response => {
                console.log(response)
                return Promise.resolve(response);
            
            }

            );
    }


   

    // getProductsMini() {
    //     return Promise.resolve(this.geGuidesList().slice(0, 5));
    // },

    // getProductsSmall() {
    //     return Promise.resolve(this.geGuidesList().slice(0, 10));
    // },

    

    // getProductsWithOrdersSmall() {
    //     return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
    // },

    // getProductsWithOrders() {
    //     return Promise.resolve(this.getProductsWithOrdersData());
    // }
};