// set base url
import axios from 'axios';
import { API_URL } from '@/service/const';
// const API_URL = 'https://127.0.0.1:8000/api/v1/';
export const PaymentService = {
    async checkout() {
        let priceId = 'price_1PNHZfGo1aKPBcZuo6jec8q9';
        // post request to https://api.guideclicks.com/create-checkout-session/
        let checkout_url = API_URL + 'init-checkout-session/';
        // alert(checkout_url);
        return axios.post(checkout_url, {
            priceId: priceId
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            if (response.data) {
                // redirect to payment page
                return Promise.resolve(response.data);
                // window.location.href = response.data.url;
                
            }
            
        }).catch(error => {
            alert("error")
            console.log(error);
            // return Promise.reject(error);
        });


    },
}