<template>
  <div>
    <!-- Your component template here -->
    
    <Vue3Lottie :animationData="AutomationJson" :height="700" :width="700" />
    <h2 class="text-center w-full">{{loading }}</h2>

  </div>
</template>

<script>
import { GuideService } from '@/service/GuideService';

import AutomationJson from './lottie/automation.json'
export default {

  data() {
    return {
      // Your component data here
      loading: 'Magic in progress, your guide will be ready in a few seconds',
      AutomationJson
    };
  },
  mounted() {
    const key = this.$route.query.s;
    
    
    this.sendMessage(key);
  },
  methods: {
    sendMessage(key) {
      let that = this;
      const extensionId = 'cicmijfcdajjoecdmhgfblnjjfodomap'; // Replace with your actual extension ID
      const message = {
        actionType: 'GET', // Replace with your actual action type
        data: key // Replace with your actual data
      };
     
      chrome.runtime.sendMessage(extensionId, message, response => {
        // Handle the response here
        if (chrome.runtime.lastError) {
          console.error(chrome.runtime.lastError.message);
          return;
        }
        if (response.images.length > 0) {
          GuideService.createGuide(
          response
        ).then((response) => {
          console.log("guide created",response);
          // redirect to guide page
          this.$router.push('/space/guide/'+response.data.id+'/');
        });
        console.log("sendMessage",response);
      }else{
        that.loading = 'No data found';
        // redirect to home /
        // this.$router.push('/spaces');
        
      }
        }
      );
    }
  }
};
</script>