
<template>
    <div class="card flex justify-center">
        <!-- <Button label="Show" @click="visible = true" /> -->
      
        <Dialog :visible="show_dialog" modal header="Update password" :style="{ width: '25rem' }" :closable="false">
            <span class="text-surface-500 dark:text-surface-400 block mb-8">Create a new password.</span>
            
            <div class="flex items-center gap-4 mb-8">
                <label for="password-change-password" class="font-semibold w-24">New Password</label>
                <InputText id="password-change-password"  type="password"  class="flex-auto" placeholder="Password ..." autocomplete="off" autofocus v-model="password_change_password" />
            </div>
            <div class="flex items-center gap-4 mb-8">
                <label for="password-change-password2" class="font-semibold w-24">Confirm New Password</label>
                <InputText id="password-change-password2" type="password" class="flex-auto" placeholder="Repeat the same password ..." autocomplete="off" @vue:mounted="textFocus" v-model="password_change_password2" />
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Chnage password" @click="change_password"></Button>

                <!-- <Button type="button" label="Cancel" severity="secondary" @click="show_dialog = false"></Button> -->
            </div>
        </Dialog>
        
    </div>
</template>


<script setup>
// importing on mount
import { onMounted } from 'vue';
// importing auth service
import { AuthService } from '@/service/AuthService';
// importing constants from const.js service
import { ROOT } from '@/service/const';
import {ref} from 'vue';

const show_dialog = ref(true);
const password_change_password = ref(null);
const password_change_password2 = ref(null);

// on mount


// change_password method
const change_password = () => {
    // check if password is empty
    if (password_change_password.value == null || password_change_password.value == ''){
        // show error message
        alert('Password cannot be empty');
        return;
    }
    // check if password is empty
    if (password_change_password2.value == null || password_change_password2.value == ''){
        // show error message
        alert('Password cannot be empty');
        return;
    }
    // check if password is empty
    if (password_change_password.value != password_change_password2.value){
        // show error message
        alert('Passwords do not match');
        return;
    }
    // get current page url
    let page_url = window.location.pathname;
    // remove the domain
    if (page_url.startsWith('/')){
        page_url = page_url.substring(1);
    }
    // call change password function
    AuthService.confirmChangePassword(page_url, password_change_password.value);
    // show success message
    // close dialog
    show_dialog.value = false;
};


</script>