<template>
    <Toast />

<div class="flex flex-wrap align-items-center justify-content-center" style="height: 100%; min-height: 100vh">

<div class="surface-card p-4 shadow-2 border-round w-full lg:w-5">
    <div class="text-center mb-5">
        <img src="/logo-no-background.png" alt="Image" height="120" class="mb-3" />
        <div class="text-900 text-3xl font-medium mb-3"></div>
       <span class="text-600 font-medium line-height-3">Alread have an account?</span>
        <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" href="/signin">Login!</a>

    </div>

    <div>
        <FloatLabel class="mb-4">
            <InputText id="username" type="text" autofocus v-model="username" class="w-full " variant="filled"  />
            <label for="username">Username</label>
        </FloatLabel>
        <FloatLabel class="mb-4">

            <label for="name" >Name</label>
            <InputText id="name" type="text" v-model="name" class="w-full" variant="filled" />
        </FloatLabel>
        
        <FloatLabel class="mb-4">

            <label for="email" >Email</label>
            <InputText id="email" type="text" v-model="email" class="w-full" variant="filled"  />
        </FloatLabel>
        <FloatLabel class="mb-4">

            <label for="password1" >Password</label>
            <InputText id="password1" type="password" v-model="password" class="w-full" variant="filled"  />
        </FloatLabel>
        <FloatLabel class="mb-4">

            <label for="password2" >Confirm password</label>
            <InputText id="password2" type="password" v-model="password2" class="w-full" variant="filled"  />
        </FloatLabel>
        <FloatLabel class="mb-4">

            <label for="industry" >Industry</label>
            <InputText id="industry"  v-model="industry"  v-on:keyup.enter="onEnter" class="w-full " variant="filled"  />
        </FloatLabel>

     

        <Button label="Register" icon="pi pi-user-plus" @click="registerApi" class="w-full"></Button>
        <Dialog v-model:visible="emailConfirmationVisible" maximizable modal header="Early Adopter Request received" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :closable="false">
            <p class="m-0">
                As soon as your request gets approved. You'll receive an email with the activation link!
            </p>
        </Dialog>
    </div>
</div>
</div>

</template>

<script setup>
import { ref } from 'vue';
import { AuthService } from '@/service/AuthService';
import { useToast } from "primevue/usetoast";
// on mount
import { onMounted } from 'vue';
const toast = useToast();
const checked = ref(true);
const email = ref('');
const password = ref('');
const password2 = ref('');
const name = ref('');
const username = ref('');
const industry = ref('');
const emailConfirmationVisible = ref(false)

// on mount
onMounted(() => {
    // check get vars for activation key
    // console.log('mounted')
    // console.log(window.location.search)
    // console.log(window.location.search.includes('activation_key'))
    let url = new URL(window.location.href);
    if (url.searchParams.get("registration") && url.searchParams.get("registration")== "failed" ) {
    
        if (url.searchParams.get("keys") && url.searchParams.get("values")){

            let splitted_keys = url.searchParams.get("keys").split(',_');
            let splitted_values = url.searchParams.get("values").split(',_');
            for (let i = 0; i < splitted_keys.length; i++) {
                toast.add({ severity: 'error', summary: splitted_keys[i], detail: splitted_values[i], life: 3000 });
            }

        }
    }else  if (url.searchParams.get("sync") && url.searchParams.get("sync")== "failed" ) {
    
   
            toast.add({ severity: 'error', summary: "Create an account first!", detail: url.searchParams.get("message") , life: 10000 });


    
}
    });


const validateForm = () =>{
    if (email.value ==''){
        // set attribute invalid
        email.setAttribute('invalid', true);
        return false;
    }
    return true;
}
const onEnter = (email, password) => {
    registerApi(email,password);
}
const  registerApi = () => {
    
            AuthService.register({
                email: email.value,
                password: password.value,
                password2: password2.value,
                username: username.value,
                name: name.value,
                industry: industry.value
            }).then((response) => {
                // console.log(response)
                // if (response.status === 201) {
                //     this.$router.push('/signin');
                // }
                // else if (response.status === 400) {
                //     console.log(response.data)

                // }
                emailConfirmationVisible.value = true

            }).catch((error) => {
                console.log(error)
                if (error.response.status === 400) {
                    console.log(error.response.data)

                    if (error.response.data.length > 0) {
                        for (let i = 0; i < error.response.data.length; i++) {
                    console.log(error.response.data)
                            console.log(error.response)
                            toast.add({ severity: 'error', summary: error.response.data[i].field, detail: error.response.data[i].message, life: 3000 });
                        }
                    }

                }
            
            })
        }
</script>

