// set base url
import axios from 'axios';
import { API_URL } from '@/service/const';
// const API_URL = 'https://127.0.0.1:8000/api/v1/';
export const ProfileService = {

    async getProfile() {
        let user_url = API_URL + 'profile/current/';
        return axios.get(user_url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            if (response.data) {
                return Promise.resolve(response.data);
            }
        }
        ).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });



    },
    async saveProfile(profile) {
        let user_url = API_URL + 'profile/current/';
        return axios.put(user_url, profile, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access')
            }
        }).then(response => {
            if (response.data) {
                return Promise.resolve(response.data);
            }
        }
        ).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });

    },
}
