<template>
  <div class="home">
    <MenuHome />
    <SpacesGrid /> 
  </div>
</template>

<script>

// // @ is an alias to /src
// import Spaces from '@/components/Spaces.vue';
// export default {
//   name: 'Spaces',
//   components: {
//     Spaces
//   }
// }
</script>
