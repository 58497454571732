// set base url
import axios from 'axios';
import { API_URL } from '@/service/const';
// const API_URL = 'https://127.0.0.1:8000/api/v1/';
export const AuthService = {
    async login(email,password) {
        // use axios to authenticate user through https://127.0.0.1:8000/api/v1/api/token/
        let login_url = API_URL + 'token/';
        return axios.post(login_url, {
            username: email,
            password: password
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data) {
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('access', response.data.access);
                localStorage.setItem('refresh', response.data.refresh);
                // redirect to home page
                window.location.href = '/spaces';
                
            }
            
        }).catch(error => {
            console.log(error);
            window.location.href = '/signin?login=failed';
        });

       
    },
    async register(data){
        // use axios to register user through https://
        let register_url = API_URL + 'register/';
        console.log(data);
        return axios.post(register_url, data,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data) {
                console.log(response);
                
            }
            
        }).catch(error => {
            console.log(error);
            // print response data
            let data = error.response.data;
            // get all keys
            let keys = Object.keys(data);
            let values_str = '';
            let keys_str = '';
            if (keys.length > 0 && keys[0].toString() == '0') {
                // redirect to home page
            //    the value is like [0: {field: 'username', message: 'A user with that username already exists.'}]
            console.log("error int")
            keys = data.map(item => item.field);
            keys_str = keys.join(',_');
            values_str = data.map(item => item.message).join(',_');
            }else{
            // make them a coma separated string
             values_str = keys.map(key => data[key]).join(',_');
             keys_str = keys.map(key => key).join(',_');
            }
            //  check if keys are integers


            window.location.href = '/register?registration=failed&keys='+keys_str+'&values='+values_str;
        });
        
    },
    async isAuthenticated() {
        var user = localStorage.getItem('user');
        if (user == null){
            return false;
        }else{
            // check if token is expired using https://127.0.0.1:8000/api/v1/user/current/
            let current_user_url = API_URL + 'profile/current/';
            let token = localStorage.getItem('access');
         try{
            await axios.get(current_user_url, {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                // check status code
                if (response.status > 400) {
                    // return false;
                }else if (response.status == 200) {
                    // return Promise.resolve(response);
                    user = response.data;
                    localStorage.setItem('username', response.data.name);
                    localStorage.setItem('name', response.data.name);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('company_name', response.data.company_name);
                    localStorage.setItem('company_url', response.data.company_url);
                    // localStorage.setItem('user', JSON.stringify(response.data));
                    // localStorage.setItem('access', response.data.access);
                    // localStorage.setItem('refresh', response.data.refresh);
                        // return response.data;
                }
        });
    }catch(error){
        return Promise.resolve(false);
    
            };
            return Promise.resolve(user);
        }
    },
    singOut() {
        localStorage.removeItem('user');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('username');
        // redirect to login page
        window.location.href = '/signin';
    },
    async activateAccount(activation_url){
        // use axios to activate user account through https://
        return axios.get(API_URL+activation_url,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data) {
                console.log(response);
                // redirect to home page
                window.location.href = '/signin?activation=activation-success';
                
            }
            
        }).catch(error => {
            console.log(error);
            window.location.href = '/signin?activation=activation-failed';
        });

        
    },
    async changePasswordRequest(email){
        // use axios to request change password through https://
        return axios.post(API_URL+'password-reset/',{
            email: email
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data) {
                console.log(response);
                // redirect to home page
                window.location.href = '/signin?reset=password-change-success';
                
            }
            
        }).catch(error => {
            console.log(error);
            window.location.href = '/signin?reset=password-change-failed';
        });
    },

    async confirmChangePassword(url, password){
        // use axios to confirm change password through https://
        return axios.post(API_URL+url,{
            password: password
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data) {
                console.log(response);
                // redirect to home page
                window.location.href = '/signin?reset=password-change-complete';
                
            }
            
        }).catch(error => {
            console.log("error",error);
            window.location.href = '/signin?reset=password-change-invalid';
        });
    }


    
    
   

};