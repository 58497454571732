<template>

</template>

<script setup>
import { ref } from 'vue';
import { AuthService } from '@/service/AuthService';
AuthService.isAuthenticated().then((res) => {
    if (!res) {
        window.location.href = "/signin";
    }

});
</script>