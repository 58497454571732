<template>
    <div class="payment">
     Succesful Payment
    
     <!-- <Spaces/> -->
    </div>
  </template>
<script>

export default {

  data() {
    return {
      loading: 'Loading...'
    };
  },
  mounted() {
    this.$router.push('/spaces');
  }
}
  </script>