
<template>
    <div class="grid">
    <div class="col-2">
        <div class="text-center p-3 border-round-sm">
            <TranslationBox/>
        </div>
    </div>
    <div class="col-8">
        <div class=" p-3 border-round-sm font-bold ">

    
    <Dialog v-model:visible="updateURLVisible" :closable="false" modal header="Edit URL" :style="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Update Guide URL.</span>
    <div class="flex align-items-center gap-3 mb-5">
        <label for="new_url" class="font-semibold w-6rem">Url</label>
        <InputText id="new_url" autofocus class="flex-auto" v-model ="guide.go_to_url" autocomplete="off" />
    </div>
    <div class="flex justify-content-end gap-2">
        <Button type="button" label="Cancel" severity="secondary" @click="cancelUrl"></Button>
        <Button type="button" label="Save" @click="saveUrl"></Button>
    </div>
</Dialog>
    <div id ="printable">
    <div class="card">
        <Toast />
        <!-- <div class="flex flex-row align-items-start justify-content-start">
            <img :src="profile.image" class="border-circle w-2rem h-2rem" />
            <span class="ml-2 font-medium">{{ profile.name }}, {{ profile.company_name }}</span>
        </div> -->
        <DataView v-if="guide" :value="guide.images"   :dir="guide.metadata.direction" :layout="layout" class="flex flex-wrap align-items-center justify-content-center">
           
            <template #grid="slotProps">
                <GuideAuthor :profile_name="profile.name" :company_name="profile.company_name"/>

            <div>
              
                <Menubar guide-html2canvas-ignore="true" dir="ltr" class="mt-5 no-print" :model="headerMenuItems">
                    <template #start>
                        <Button :icon="directionIcon" text :label="guide.metadata.direction == 'rtl'? 'Ar':'En'" severity="contrast" @click="toggleDirection"  />
                        <Button v-if="guide.is_public" icon="pi pi-eye" text label="Preview" severity="success"  @click="publicPreview"  />
                        <Button v-else icon="pi pi-eye" text label="Preview" severity="danger"  @click="publicPreview"  />


                    </template>
                    <template #item="{ item, props, hasSubmenu }">
                        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                            <a :href="href" v-bind="props.action" @click="navigate">
                                <span :class="item.icon" />
                                <span class="ml-2">{{ item.label }}</span>
                            </a>
                        </router-link>
                        <a v-else  :href="item.url" :target="item.target" v-bind="props.action">
                            <span :class="item.icon" />
                            <span class="ml-2">{{ item.label }}</span>
                            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
                        </a>

                    </template>
                    <template #end>

                        <ToggleButton v-model="guide.is_public" @change="syncGuide" onLabel="Public" offLabel="Private" onIcon="pi pi-globe" 
            offIcon="pi pi-lock" class="ml-2 mr-2" aria-label="Do you confirm" />
                        <AutoComplete v-model="currentSpace"placeholder="Assign to a space" dropdown :suggestions="spacesFiltered" optionLabel="name" @item-select="spaceSelected($event)" @complete="search($event)" />
                    </template>
                </Menubar>
                
             

                <h1><Textarea type="text" @change="syncGuide" placeholder="title" style="font-size: inherit"  rows="1" class="w-full" v-model="guide.metadata.title" autoResize/></h1>
                
                <!-- <Textarea v-model="images.description" placeholder="Summary" rows="5" class="w-full" /> -->
                <Card>
                    <template #title>
                       
                        </template> 
                        <template #content>
                                
                            <div class="flex flex-row align-items-center " >
                                <Button  icon="pi pi-link" text  class="w-2rem h-2rem p-0"   />
                                
                                <span class="mr-2"> <metadata class="go_to"></metadata> </span>
                                <a :href="guide.go_to_url" dir="ltr" target="_blank">{{ guide.go_to_url }}</a> 
                                <Button icon="pi pi-pencil" class="no-print" text rounded aria-label="Edit" @click="updateUrl"/>
                            </div>

                        </template>
                </Card>
            </div>
                <div class="list list-nogutter">
                    
                    <TransitionGroup name="fade"  class="animation-contianer">
                        <div v-for="(item, index) in slotProps.items" :key="item.id"   style="max-width: 700px">
                    <section>

                            
                                <!-- <KhatwaCard :item="item" :index="index" :active="0" :moveUp="moveUp" :moveDown="moveDown" :deleteItem="deleteItem" :getCircleStyle="getCircleStyle" :length="slotProps.items.length" /> -->
                                <Card class="list-item" style=" margin:15px;">
                               
                               <template #title>
                                
                                   <div class="flex flex-row" >
                                       <Button  rounded :label="`${index+1}`" class="w-2rem h-2rem p-0" style="margin:5px;" />
                                       <Textarea type="text" @change="syncGuide" rows="1" class="w-full" autoResize style="color: var(--secondary-color);" @vue:mounted="textFocus" v-model="item.actionText" />
                                       <Button v-if ="item.id" icon="pi pi-trash" severity="secondary" @click="deleteItem(index)" text   class="p-button-iconp-button-icon-onlyp-button p-component p-button-icon-only p-button-rounded p-button-text no-print" guide-html2canvas-ignore="true"
                                     v-tooltip.bottom="{
                                            value: 'Delete item',
                                            pt: {
                                                arrow: {
                                                    style: {
                                                        borderBottomColor: 'var(--p-primary-color)'
                                                    }
                                                },
                                                text: 'bg-primary font-medium'
                                            }
                                        }"  />
                                   </div>
                               </template>
                               <template #content >
                                   <ToggleButton v-model="item.showDescription" onLabel="Description" offLabel="Description" onIcon="pi pi-minus"  offIcon="pi pi-plus" guide-html2canvas-ignore="true" class="w-9rem m-2 no-print" aria-label="Do you confirm" />
                                   <p class="m-2" v-if="item.showDescription == true">
                                       <Textarea v-model="item.description"  rows="1" style="color: var(--secondary-color);" class="w-full" @vue:mounted="textFocus" autoResize />
                                   </p>
                                   <div v-if="item.imageBase64" class="mx-auto relative">
                                       <img class="border-round w-full" :src="`${item.imageBase64}`"  :alt="item.name"/>
                                       <img v-if ="item.action" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Red_circle.svg/400px-Red_circle.svg.png"  class="absolute" :style="`${getCircleStyle(item)}`" outlined></img>
                                   </div>
                                   <div v-if = "!item.imageBase64" class="mx-auto relative no-print">
                                    <!-- <img class="border-round w-full" :src="`${item.imageBase64}`"   :alt="item.name"/> -->
                                    <FileUpload mode="advanced" name="demo[]" url="/api/upload" accept="image/*" customUpload @uploader="customBase64Uploader($event,item)" />
                                    <!-- <Button label="Upload"  severity="secondary" /> -->
                                   </div>
                                   <div class="flex-row">
                                   <div class="flex flex-row align-items-center justify-content-center">
                                   <Button  text  v-if="index < slotProps.items.length -1" @click="addStepBelow(index)" icon="pi pi-plus" class="no-print" severity="secondary" rounded label=""  style="margin:5px;" guide-html2canvas-ignore="true" 
                                   v-tooltip.bottom="{
                                            value: 'Add a step below',
                                            pt: {
                                                arrow: {
                                                    style: {
                                                        borderBottomColor: 'var(--p-primary-color)'
                                                    }
                                                },
                                                text: 'bg-primary font-medium'
                                            }
                                        }"  />
                                   <Button  text  v-if="index < slotProps.items.length -1"  icon="pi pi-arrow-down" class="no-print  p-button-rounded" rounded @click="moveDown(index)" style="margin:5px;" guide-html2canvas-ignore="true" 
                                   v-tooltip.bottom="{
                                            value: 'Move step down',
                                            pt: {
                                                arrow: {
                                                    style: {
                                                        borderBottomColor: 'var(--p-primary-color)'
                                                    }
                                                },
                                                text: 'bg-primary font-medium'
                                            }
                                        }"  />
                                   <Button  text  v-if="index > 0" icon="pi pi-arrow-up"   @click="moveUp(index)" class="no-print" rounded style="margin:5px;" guide-html2canvas-ignore="true"
                                    v-tooltip.bottom="{
                                            value: 'Move step up',
                                            pt: {
                                                arrow: {
                                                    style: {
                                                        borderBottomColor: 'var(--p-primary-color)'
                                                    }
                                                },
                                                text: 'bg-primary font-medium'
                                            }
                                        }"  /> 
                                
                                       <!-- <SpeedDial :model="CardActions" :radius="80" type="semi-circle"  showIcon="pi pi-bars" direction="down" :key="item.id"   :transitionDelay="80" > -->
                                        <!-- </SpeedDial> -->

                                </div>
                                 
                               </div>

                               </template>

                           </Card>
                    </section>
                

                        </div>
                       
                    </TransitionGroup>
                   
                </div>
            
            </template>
        </DataView>
        <div class="flex align-content-center flex-wrap w-full m-3">
            <p class="w-full flex justify-content-center align-items-center">{{ profile.company_name }}</p>
            <a :href="profile.company_url" target="_blank" class="text-underline text-secondary text-sm  mb-3 w-full flex justify-content-center align-items-center">{{ profile.company_url }}</a>
            
        </div>
    </div>
</div>
</div>
    </div>
    <div class="col"  style="display:none;">
        <div class="text-center p-3 border-round-sm  ">
            
        </div>
    </div class="col-2">
</div>
  </template>

  <script>
  import { GuideService } from '@/service/GuideService';
  import { SpaceService } from '@/service/SpaceService';
  import { ref } from 'vue';
  import html2pdf from "html2pdf.js";
  import { translationData } from '@/i18n/ar';
import TranslationBox from './TranslationBox.vue';
import { LanguageService } from '@/service/LanguageService';
  export default {
    data() {
        return {
            images: null,
            metadata: {'direction': 'ltr'},
            is_public: false,
            layout: 'grid',
            spaces: [],
            spacesFiltered: [],
            currentSpace: {"id":0, "name":""},
            updateURLVisible: false,
            cachedUrl: null,
            direction: 'ltr',
            directionIcon: 'pi pi-arrow-right',
            fileToUpload: null,
            guide :null,
            last_saved: "Auto-save enabled",
            profile: {
                image: '', // Replace with your actual image path
                name: '', // Replace with your actual name
                company_name: '', // Replace with your actual company name
                company_url: '', // Replace with your actual company URL
            }


        }
    },
    mounted() {
        this.profile= {
            image: localStorage.getItem('image'),
            name: localStorage.getItem('name'),
            company_name: localStorage.getItem('company_name'),
            company_url: localStorage.getItem('company_url')

        }
        GuideService.getGuide(this.$route.params.id).then((guide) => {
            console.log("guide",this.guide)
            guide.metadata["profile"] = this.profile;

            this.guide = guide;
            if (!this.guide.go_to_url){
                if(this.guide.images.length > 0){
                this.guide.go_to_url = this.guide.images[0].url;
            }
            }
            console.log("guide",this.guide)

            this.images = guide.images;
        
            this.metadata = guide.metadata;
            this.is_public = guide.is_public;

            if (guide.space){
                SpaceService.getSpace(guide.space).then((space) => {this.currentSpace = space;});
            }
             
            });
        SpaceService.getSpaces(false).then((spaces) => {this.spacesFiltered = spaces; this.spaces = spaces;});
    },
    methods: {
        exportToPDF() {
            window.print();
    },
        getCircleStyle(item) {
            return "left: " + item.x + "%; top: " + item.y + "%; max-width: 50px; max-height: 50px; margin-top: -25px; margin-left: -25px;";
        },
        moveDown(index){
            // this.guide.images = _shuffle(this.guide.images)
            if(index < this.guide.images.length - 1){
                [this.guide.images[index], this.guide.images[index+1]] = [this.guide.images[index+1], this.guide.images[index]];
                // swap images[index].sequence with images[index+1].sequence
                let tmp = this.guide.images[index].sequence;
                this.guide.images[index].sequence = this.guide.images[index+1].sequence;
                this.guide.images[index+1].sequence = tmp;

                // GuideService.updateGuide(this.$route.params.id, this.guide.images, this.guide.metadata);
                this.syncGuide()
            }
        },
        moveUp(index){
            if(index > 0){
                [this.guide.images[index], this.guide.images[index-1]] = [this.guide.images[index-1], this.guide.images[index]];
                let tmp = this.guide.images[index].sequence;
                this.guide.images[index].sequence = this.guide.images[index-1].sequence;
                this.guide.images[index-1].sequence = tmp;

                // GuideService.updateGuide(this.$route.params.id, this.guide.images, this.guide.metadata);
                this.syncGuide()
            }
        },
        addStepBelow(index){
            // move all sequence numbers down by 1
            for (let i = index + 1; i < this.guide.images.length; i++){
                this.guide.images[i].sequence += 1;
            }
            let newStep = {
                ref:'newInput',
                imageBase64: '',
                description: '',
                actionText: '',
                stepType: 'step', //step, header, or image
                x: 50,
                y: 50,
                showDescription: false,
                sequence: this.guide .images[index].sequence + 1
            };

            this.guide.images.splice(index + 1, 0, newStep);
            console.log(this.$refs)
            
            // this.syncGuide();

        }
        ,
        async syncGuide(){
            this.guide.images.space = this.currentSpace.id;
            // GuideService.updateGuide(this.$route.params.id, this.guide.images, this.guide.metadata, this.is_public);
            console.log("currently updating", this.guide)
            let data = await GuideService.update(this.$route.params.id, this.guide);
            // this.guide.images = data.data.images;
            
            // console.log("synced data",data)
            this.last_saved = "Last saved @" + new Date().toLocaleString();

        },
        deleteItem(index){
            GuideService.deleteGuideStep(this.guide.images[index].id);
            this.guide.images.splice(index, 1);
            this.syncGuide()
            // GuideService.updateGuide(this.$route.params.id, this.guide.images, this.guide.metadata);
        },
        updateUrl(){
            this.cachedUrl = this.guide.go_to_url;
            this.updateURLVisible = true;
        },
        saveUrl(){
            this.updateURLVisible = false;
            // GuideService.updateGuide(this.$route.params.id, this.guide.images, this.guide.metadata);
            this.syncGuide()
        },
        cancelUrl(){
            this.guide.go_to_url = this.cachedUrl;
            this.updateURLVisible = false;
        },
         textFocus  (el) {
            console.log(el)
            if (el.el?.value === '')
               { 
                el.el?.focus();

               }

            // I needed to use this with the InputOtp component and used the following instead
            // el.el?.firstChildElement.focus()
            },
            publicPreview(){
                window.open('/public'+window.location.pathname, '_blank');
            },
        async toggleDirection(){
            let languageFrom = this.guide.metadata.direction === 'ltr' ? 'en' : 'ar';
            let languageTo = this.guide.metadata.direction === 'ltr' ? 'ar' : 'en';
            this.guide.metadata.direction = this.guide.metadata.direction=== 'ltr' ? 'rtl' : 'ltr';
            
            this.directionIcon =this.guide.metadata.direction=== 'ltr' ? 'pi pi-arrow-right' : 'pi pi-arrow-left';
            let translationData = await LanguageService.getLanguages()
            // loop through all guide images and update title
            // this.guide.images.forEach((item) => {
            //     this.actionText = LanguageService.replaceTermsInString(translationData, 'ar',item.actionText);
            // });
            // make it using map
            this.guide.images = this.guide.images.map((item) => {
                item.actionText = LanguageService.replaceTermsInString(translationData, languageFrom, languageTo,item.actionText);
                return item;
            });
            this.syncGuide();
        },
         search(event) {
            //    this.spacesFiltered = this.spaces.map((item) => item.name);
            this.spacesFiltered = this.spaces.filter((item) => item.name === event.query);
            if (!event.query.trim().length) {
                this.spacesFiltered = [...this.spaces];
            } else {
                this.spacesFiltered = this.spaces.filter((item) => {
                    return item.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        } ,
        // spaceSelected(event) {
        //     GuideService.updateGuideSpace(this.$route.params.id, event.value.id);
            
        // },
    
        async customBase64Uploader (event, item)  {
    const file = event.files[0];
    const reader = new FileReader();
    let blob =  await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);

    reader.onloadend = function () {
        const base64guide = reader.result;
        console.log(base64guide);
        GuideService.updateGuideStep(item.id, {imageBase64: base64guide}).then((guide) => {
            item.imageBase64 = base64guide;
        });
    };
}
       
     
    }
  }
  </script>
  <script setup>
  import { ref } from 'vue';
  import { useToast } from "primevue/usetoast";
  import { useRoute } from 'vue-router';

    const toast = useToast();
    const checked = ref(true);

const route = useRoute();  
    const param_id = route.params.id; // read parameter id (it is reactive) 

    const headerMenuItems = ref([
        
    
    // {
    //     label: 'Language',
    //     icon: 'pi pi-language',
    //     items: [
    //         {
    //             label: 'English',
    //         },
    //         {
    //             label: 'Arabic',
    //             command: () => {
    //                 replaceTerms('ar');
    //             }
    //         },
    //         {
    //             label: 'French',
    //         },
            
    //     ]
    // },
                // {
                // label: 'Preview',
                // icon: 'pi pi-eye',
                // command: () => {
                // window.open('/public'+window.location.pathname, '_blank');
                // }
                // },
                {
                route: '/public'+window.location.pathname
               
                },
                {
                    
                }
                // {
                // label: 'PDF',
                // icon: 'pi pi-download',
                // command: () => {
                //     window.print();
                // }
                // },
                // {
                //     label: 'Link',
                //     icon: 'pi pi-link',
                //     command: () => {
                //         navigator.clipboard.writeText(window.location.href);
                //         toast.add({ severity: 'info', summary: 'Link sharing', detail: 'Copied to clipboard', life: 3000 });
                //     }
                // },
    // {
    //             label: 'Share',
    //             icon: 'pi pi-share-alt',
    //             items: [
    //                 {
    //                     label: 'PDF',
    //                     icon: 'pi pi-download',
    //                     command: () => {
    //                         window.print();
    //             }
    //                 },
    //                 {
    //                     label: 'Link',
    //                     icon: 'pi pi-link'
    //                 }
    //             ]
    //         }
]);
// const CardActions = ref()
const spaceSelected = (event) => {
            GuideService.updateGuideSpace(param_id, event.value.id).then((guide) => {
              toast.add({ severity: 'success', summary: 'Space', detail: 'Guide moved to ' + event.value.name, life: 3000 });
            });
            
        }
  </script>